import React, { useState } from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Certificates = () => {
  const lang = "de";

  const [activeDepartment, setActiveDepartment] = useState("1");
  const [openPopup, setOpenPopup] = useState(false);
  const [popupImage, setPopupImage] = useState("");

  const openPopupHandler = (image) => {
    setOpenPopup(true);
    setPopupImage(image);
  };

  const closePopupHandler = () => {
    setOpenPopup(false);
    setPopupImage("");
  };

  return (
    <Layout
      seoTitle="Zertifikate"
      lang={lang}
      translationPL="/certyfikaty/"
      translationEN="/en/certificates/"
    >
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SmallBanner title="Zertifikate" lang={lang} />
      {openPopup ? (
        <div className="certificates__popup">
          <button
            onClick={() => closePopupHandler()}
            className="popup__background"
          />
          <div className="popup__content">
            <img className="popup__image" src={popupImage} alt="Certyfikat" />
            <button
              className="popup__close"
              onClick={() => closePopupHandler()}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <section className="certificates">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="certificates__tab-nav mb-50">
                <button
                  onClick={() => setActiveDepartment("1")}
                  className={`btn btn--plain btn--center ${
                    activeDepartment === "1" ? "current" : ""
                  } `}
                >
                  PalettenWerk
                </button>
                <button
                  onClick={() => setActiveDepartment("2")}
                  className={`btn btn--plain btn--center ${
                    activeDepartment === "2" ? "current" : ""
                  } `}
                >
                  Palko
                </button>
              </div>
            </div>
            <div className="col-12">
              {activeDepartment === "1" ? (
                <>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert1.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert1.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">
                      ISO 9001:2015 Qualitätszertifikat
                      </h3>
                      <p className="xs mb-20">
                      Seit 2005 sind wir mach dem ISO-Qualitätsstandard zertifiziert. Dank den von uns entwickelten Verfahren und Anweisungen werden alle Prozesse innerhalb des Unternehmens, von der Rohstoffbeschaffung über die Produktion bis hin zum Verkauf, optimiert. Diese Prozesse sind an die Besonderheiten unserer Branche angepasst. Die jährlichen Audits im Rahmen des erhaltenen Zertifikats bescheinigen die Übereinstimmung der Praxis mit den von uns erstellten Verfahren und Anweisungen
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/iso.jpg")}
                        alt="ISO"
                      />
                      <a
                        href={require("../../assets/img/pwCert1.pdf")}
                        download
                        className="item__btn btn"
                        data-hover="Herunterladen"
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(require("../../assets/img/ISO14001_DE.jpg"))
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/ISO14001_DE.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Umweltzertifikat ISO 14001:2015
                      </h3>
                      <p className="xs mb-20">
                      Wir verfügen über ein Zertifikat, das bescheinigt, dass das von der Organisation
                      implementierte Umweltmanagementsystem der Norm ISO 14001:2015 entspricht und die
                      Organisationsstruktur, Planung, Zuweisung von Verantwortung und Ressourcen abdeckt, 
                      die für die Entwicklung, Umsetzung und Durchführung des Managements in einer Weise erforderlich 
                      sind, die dies berücksichtigt aktuelle Umweltprobleme. Das System ist entsprechend an die Besonderheiten 
                      unserer Branche angepasst. Jährliche Audits im Rahmen des erhaltenen Zertifikats bestätigen die 
                      Konformität des gesamten Unternehmensmanagementsystems mit der Norm.
                      </p>
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../../assets/img/ISO14001_DE.pdf")}
                          data-hover="Herunterladen"
                          download
                        >
                          Herunterladen
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert2.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert2.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">ISPM 15 Zertifikat (IPPC)</h3>
                      <p className="xs mb-20">
                      Als eine der ersten Firmen haben wir ein Zertifikat erlangt, mit dem die Möglichkeit der technischen Wärmebehandlung von Paletten nach dem ISPM15-Standard bestätigt wird. Damit können wir die Waren auf unseren Paletten außerhalb der Europäischen Union und in alle Länder versenden, die eine solche Behandlung erfordern (z.B. die Ukraine, Norwegen, Großbritannien). Dieser Prozess verhindert die Übertragung von Insekten auf andere Ökosysteme
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/itd.png")}
                        alt="ITD"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/pwCert2.pdf")}
                        data-hover="Herunterladen"
                        download
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert3.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert3.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">PEFC Zertifikat</h3>
                      <p className="xs mb-20">
                      Das PEFC Zertifikat bescheinigt, dass das Holz, das wir in unserem Unternehmen verwenden, aus einer legalen Quelle stammt, und stellt außerdem sicher, dass die Waldressourcen, nachdem der Baum gefällt und für industrielle Zwecke genutzt wurde, wieder aufgefüllt werden. Unser Unternehmen überwacht den eingehenden Holzrohstoff und sorgt somit für die nachhaltige Entwicklung. Wir verfügen über eine verantwortungsvolle, ökologisch orientierte Rohstoffbewirtschaftungspolitik.
                      </p>

                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/pwCert3.pdf")}
                        data-hover="Herunterladen"
                        download
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert4.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert4.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">EPAL Lizenz</h3>
                      <p className="xs mb-20">
                      Wir waren eines der ersten Unternehmen in Polen, das 1998 eine Lizenz für die Herstellung von EPAL Standardpaletten erhalten hat. Zugleich waren wir Mitbegründer des Polnischen EPAL Nationalkomitees. Diese Lizenz garantiert, dass die Palette in Göße 1200x800cm nach dem von der UIC geschaffenen Standard qualitativ hergestellt wird. Die Qualität der Palette wird mehrmals monatlich von einer unabhängigen Prüfgesellschaft kontrolliert. Dies gewährleistet die volle Austauschbarkeit der Palette und ermöglicht ihren weltweiten Verkauf. Wir produzieren alle Arten von Paletten, die im EPAL Portfolio enthalten sind.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/quality-sign.png")}
                        alt="EPAL"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/pwCert4.pdf")}
                        data-hover="Herunterladen"
                        download
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert5.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert5.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">AEO-Zertifikat</h3>
                      <p className="xs mb-20">
                      Seit Beginn unserer Tätigkeit sind wir in Polen der größte Exporteur von Paletten in und außerhalb Europas. Dank unserer Zuverlässigkeit haben wir die Möglichkeit erhalten, vereinfachte Exportabfertigung vorzunehmen, was den Versand unserer Waren ins Ausland erheblich beschleunigt und optimiert hat,
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/AEO.png")}
                        alt="AEO"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/pwCert5.pdf")}
                        data-hover="Herunterladen"
                        download
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {activeDepartment === "2" ? (
                <>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/palkoCert1.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/palkoCert1.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">
                      ISO 9001:2015 Qualitätszertifikat
                      </h3>
                      <p className="xs mb-20">
                      Seit 2005 sind wir mach dem ISO-Qualitätsstandard zertifiziert. Dank den von uns entwickelten Verfahren und Anweisungen werden alle Prozesse innerhalb des Unternehmens, von der Rohstoffbeschaffung über die Produktion bis hin zum Verkauf, optimiert. Diese Prozesse sind an die Besonderheiten unserer Branche angepasst. Die jährlichen Audits im Rahmen des erhaltenen Zertifikats bescheinigen die Übereinstimmung der Praxis mit den von uns erstellten Verfahren und Anweisungen.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/iso.jpg")}
                        alt="ISO"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/palkoCert1.pdf")}
                        data-hover="Herunterladen"
                        download
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/ispm15_certificate.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/ispm15_certificate.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">ISPM 15 Zertifikat (IPPC)</h3>
                      <p className="xs mb-20">
                      Als eine der ersten Firmen haben wir ein Zertifikat erlangt, mit dem die Möglichkeit der technischen Wärmebehandlung von Paletten nach dem ISPM15-Standard bestätigt wird. Damit können wir die Waren auf unseren Paletten außerhalb der Europäischen Union und in alle Länder versenden, die eine solche Behandlung erfordern (z.B. die Ukraine, Norwegen, Großbritannien). Dieser Prozess verhindert die Übertragung von Insekten auf andere Ökosysteme.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/itd.png")}
                        alt="ITD"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/ispm15_certificate.pdf")}
                        data-hover="Herunterladen"
                        download
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert3.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert3.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">PEFC Zertifikat</h3>
                      <p className="xs mb-20">
                      Das PEFC Zertifikat bescheinigt, dass das Holz, das wir in unserem Unternehmen verwenden, aus einer legalen Quelle stammt, und stellt außerdem sicher, dass die Waldressourcen, nachdem der Baum gefällt und für industrielle Zwecke genutzt wurde, wieder aufgefüllt werden. Unser Unternehmen überwacht den eingehenden Holzrohstoff und sorgt somit für die nachhaltige Entwicklung. Wir verfügen über eine verantwortungsvolle, ökologisch orientierte Rohstoffbewirtschaftungspolitik.
                      </p>
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/palkoCert3.pdf")}
                        data-hover="Herunterladen"
                        download
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/palkoCert4.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/palkoCert4.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">EPAL Lizenz</h3>
                      <p className="xs mb-20">
                        Wir waren eines der ersten Unternehmen in Polen, das 1998 eine Lizenz für die Herstellung von EPAL Standardpaletten erhalten hat. Zugleich waren wir Mitbegründer des Polnischen EPAL Nationalkomitees. Diese Lizenz garantiert, dass die Palette in Göße 1200x800cm nach dem von der UIC geschaffenen Standard qualitativ hergestellt wird. Die Qualität der Palette wird mehrmals monatlich von einer unabhängigen Prüfgesellschaft kontrolliert. Dies gewährleistet die volle Austauschbarkeit der Palette und ermöglicht ihren weltweiten Verkauf. Wir produzieren alle Arten von Paletten, die im EPAL Portfolio enthalten sind.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/quality-sign.png")}
                        alt="EPAL"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/palkoCert4.pdf")}
                        data-hover="Herunterladen"
                        download
                      >
                        Herunterladen
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Certificates;
